<script setup>
const { logout } = useAuth();
const menu = ref(false);
const user = useState('user')

function showMenu() {
  menu.value = !menu.value;
  console.log(menu.value);
}

function signOut() {
  logout();
  const router = useRouter();        
  router.push('/login');     
}

const navLinks = [
  {
    label: "Home",
    to: "/",
  },
  {
    label: "Warning Overview",
    to: "/overview",
  },
  {
    label: "Add Warning",
    to: "/addWarning",
  },
];
</script>

<template>
  <div>
    <div
      class="w-full bg-metgisblue relative flex h-16 items-center justify-evenly"
    >
      <div class="absolute left-4 ">
        <UButton color="blue" @click="showMenu()"
          ><UIcon
            class="text-4xl text-white"
            name="i-heroicons-bars-3-20-solid"
        /></UButton>
      </div>
      <div class="absolute left-24">
        <NuxtImg src="/logo.png" class="h-10 md:block hidden" />
      </div>

      <div class="text-center md:mx-auto w-1/3 mr-32 ml-28 text-xl font-mono z-10">
        <span>Hello, {{ user.lastname }} {{ user.firstname }}</span>
      </div>
      <div class="absolute right-12 z-[80]">
        <UButton class="mx-auto" color="red" @click="signOut()">
          <span class="mx-auto">Logout</span>
        </UButton>
      </div>
    </div>
    <div
      v-if="menu"
      class="fixed left-0 h-screen lg:w-80 z-40 w-full bg-metgisblue"
    >
      <UVerticalNavigation
        :links="navLinks"
        :ui="{ padding: 'py-8', size: 'text-lg' }"
      />
    </div>
  </div>
</template>